.App {
  height: 100vh;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  overflow: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.productHover {
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.productHover:hover {
  background-color: rgba(215, 213, 213, 0.2);
  transition: 0.3s;
}

.productHover:active {
  background-color: rgba(215, 213, 213, 0.4);
}

.filterText {
  cursor: pointer;
  transition: 0.5s;
  background: #e1e5e5;
  padding: 5px;
  border-radius: 5px;
  margin: 2px 4px 2px 0;
}

.filterText:hover {
  text-decoration: line-through;
  transition: 0.2s;
  background-color: rgba(147, 146, 146, 0.4);
}

.filterText:active {
  background-color: rgba(108, 107, 107, 0.4);
}

.searchInstead {
  cursor: pointer;
  color: rgb(33, 91, 216);
}

.searchInstead:hover {
  color: rgb(97, 116, 157);
}

.productTitle {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 1060px) {
  .searchMessage{
    flex-direction: column;
  }
}
